export class UserLimitExceeded extends Error {
  constructor(message) {
    super(message);
    this.name = 'UserLimitExceeded';
  }
}
Object.defineProperty(UserLimitExceeded, 'name', {
  value: 'UserLimitExceeded',
  writable: false,
  configurable: false,
});

export class SystemLimitExceeded extends Error {
  constructor(message) {
    super(message);
    this.name = 'SystemLimitExceeded';
  }
}
Object.defineProperty(SystemLimitExceeded, 'name', {
  value: 'SystemLimitExceeded',
  writable: false,
  configurable: false,
});
