import {useState, useEffect, useCallback} from 'react';
import {LOADING, SIGNING_OUT_EVENT} from '../constants';
import {isSigningOutState} from "../storageUtils";

const useLoadingOrSigningOut = (status) => {
    const [isLoading, setIsLoading] = useState(true);

    const checkSignOutStatus = useCallback(() => {
        setIsLoading(status === LOADING || isSigningOutState());
    }, [status]);

    useEffect(() => {
        checkSignOutStatus();
        window.addEventListener(SIGNING_OUT_EVENT, checkSignOutStatus);

        return () => {
            window.removeEventListener(SIGNING_OUT_EVENT, checkSignOutStatus);
        };
    }, [checkSignOutStatus]);

    return isLoading;
};

export default useLoadingOrSigningOut;