// package imports
import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@emotion/react';

// local imports
import { readNDJSONStream } from 'services/clientApiUtils';
import UserMessage from './UserMessage';
import AssistantMessage from './AssistantMessage';
import ErrorMessage from './ErrorMessage';
import { escapeHtml, generateUUID } from 'services/stringUtils';
import { logClientException } from 'appinsights/clientAppInsights';
import Sidebar from './Sidebar';
import { useSearchParams } from 'services/hooks/useSearchParams';
import { useFetchConversation } from './hooks/conversationHistoryhooks';
import { SystemLimitExceeded, UserLimitExceeded } from 'services/smartChatUtils/smartChatErrors';

const RAGChat = () => {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conversationState, setConversationState] = useState(null);
  const textBoxRef = useRef();
  const theme = useTheme();

  const searchId = useSearchParams('cid');

  const { data, status, error } = useFetchConversation(searchId);

  useEffect(() => {
    if (status === 'success' && data && data.messages) {
      setConversationState({ id: data.id, startTime: data.startTime, question: data?.messages[0]?.content });
      setMessages(data?.messages);
    } else if (status === 'error') {
      // todo error handling
    }
  }, [status, data, error]);

  const sendChatMessage = async (q) => {
    let id = null;
    let startTime = new Date().getTime();
    try {
      if (loading) {
        return;
      }
      let convContext = conversationState;
      if (!convContext) {
        convContext = { id: generateUUID(), startTime: new Date().toISOString(), question: q };
        setConversationState(convContext);
      }
      const reqBody = {
        messages: [...messages, { 'content': q, 'role': 'user' }],
        sessionState: 'TODO',
        context: convContext,
      };

      setLoading(true);
      // add user question to chat
      setMessages((prev) => [...prev, { content: q, role: 'user', sessionState: reqBody.sessionState }]);

      const message = {
        messageId: generateUUID(),
        content: '',
        role: 'assistant',
        followUpQuestions: [],
        sessionState: reqBody.sessionState,
      };
      setMessages((prev) => [...prev, message]);

      const timeout = 60000;
      const controller = new AbortController();
      id = setTimeout(() => controller.abort('timeout'), timeout);

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
        signal: controller.signal,
      };
      const response = await fetch('/api/search/chat', options);
      if (!response.ok) {
        if (response.status === 500) {
          // try to read a json error message
          const chatError = await response.json();
          if (chatError.message) {
            throw chatError;
          }
        }
        throw new Error(`Chat request failed: ${response.status}`);
      }
      if (!response.body) {
        throw new Error('Chat request failed: no response body');
      }

      for await (const chunk of readNDJSONStream(response.body)) {
        if (chunk.delta.content) {
          setMessages((prev) => {
            message.content += chunk.delta.content;
            const newMessages = prev.map((m) => {
              if (m.messageId === message.messageId) {
                return { ...m, content: message.content };
              }
              return m;
            });
            return newMessages;
          });
          if (textBoxRef.current && typeof window !== 'undefined') {
            window.setTimeout(() => {
              textBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 250);
          }
        }
      }
    } catch (error) {
      if (error.name === UserLimitExceeded.name || error.name === SystemLimitExceeded.name) {
        setMessages((prev) => [...prev.slice(0, prev.length - 1), { content: error.message, role: 'error' }]);
      } else {
        console.error('error sending question:', error);
        logClientException(error);
        setMessages((prev) => [
          ...prev.slice(0, prev.length - 1),
          { content: 'Sorry, I am unable to answer that question at the moment. Please try again later.', role: 'error' },
        ]);
      }
    } finally {
      setLoading(false);
      if (id) {
        clearTimeout(id);
      }
      console.log(`sendChatMessage done in ${new Date().getTime() - startTime}ms`);
    }
  };

  const resetConversation = () => {
    setMessages([]);
    setConversationState(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: '1rem',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          sx={{
            width: '5%',
            display: 'flex',
            flexDirection: 'column',
            width: '15%',
            padding: '1rem',
            backgroundColor: { xs: theme.palette.common.white, lg: theme.palette.grey['100'] },
          }}
        >
          <Sidebar newConverstionHandler={resetConversation} />
        </Box>
        <Box
          sx={{
            m: { xs: 0, sm: 0, md: '40px', padding: '1rem' },
            backgroundColor: theme.palette.grey['100'],
            borderRadius: '1rem',
            minHeight: '50vh',
            flexGrow: 4,
          }}
        >
          {/* <pre>{JSON.stringify(messages, null, 2)}</pre> */}
          {messages?.map((message, index) =>
            message.role === 'user' ? (
              <UserMessage key={index} index={index} message={message} />
            ) : message.role === 'assistant' ? (
              <AssistantMessage key={index} index={index} message={message} handleFollowUp={sendChatMessage} />
            ) : (
              <ErrorMessage key={index} index={index} message={message} />
            )
          )}
          <TextField
            label='Ask a question'
            id='rag-chat-question'
            name='rag-chat-question'
            fullWidth={true}
            multiline
            maxRows={3}
            value={question}
            disabled={loading}
            onChange={(e) => setQuestion(escapeHtml(e.target.value))}
            sx={{
              marginTop: '1rem',
              '& .MuiInputBase-root': { backgroundColor: theme.palette.common.white },
              '& .MuiOutlinedInput-root': { borderRadius: '1rem' },
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (!question || question.trim() === '') {
                  return;
                }
                sendChatMessage(question);
                setQuestion('');
                e.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    ref={textBoxRef}
                    disabled={loading}
                    aria-label='send question'
                    edge='end'
                    onClick={() => {
                      if (!question || question.trim() === '') {
                        return;
                      }
                      sendChatMessage(question);
                      setQuestion('');
                    }}
                  >
                    <SendIcon color='primary' />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RAGChat;
