import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

const Heading1 = (props) => {
  const Homepage = styled.span`
    @media screen and (min-width: 992px) {
      ${props.homepage && 'text-transform: uppercase;'}
      ${props.homepage ? 'font-size: 68px;' : 'font-size: 50px;'}
      ${props.homepage ? 'line-height: 70px;' : 'line-height: 54px;'}
      ${props.homepage ? 'font-weight: 700;' : 'font-weight: 500;'}
    }
    @media screen and (max-width: 991px) {
      ${props.homepage && 'text-transform: uppercase;'}
      ${props.homepage ? 'font-size: 42px;' : 'font-size: 34px;'}
      ${props.homepage ? 'line-height: 46.2px;' : 'line-height: 34.5px;'}
      ${props.homepage ? 'font-weight: 500;' : 'font-weight: 700;'}
    }
  `;

  const { color, children, underline, uppercase, ...rest } = props;
  return (
    <Typography {...rest} component='h1' color={color}>
      <Homepage>{children}</Homepage>
    </Typography>
  );
};

export default Heading1;
