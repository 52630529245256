import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import PleaseWait from '../common/PleaseWait';
import { KitGridContainer, KitGridItem, KitCard, KitCardBody, KitButton } from '@boystownorg/bi-cms-component-lib';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import { logClientException } from 'appinsights/clientAppInsights';
import { ERROR_MESSAGES, TZ_MAP } from 'services/constants';
import DialogWithX from './pageComponents/DialogWithX';
import PlanOptionLearnMore from './pageComponents/PlanOptionLearnMore';
import MuiAlert from '@mui/material/Alert';
import Heading6 from 'components/common/subcomponents/Typography/Heading6';
import BodyCopySmall from 'components/common/subcomponents/Typography/BodyCopySmall';
import { Freeform } from 'components/common/Freeform';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { parseBlocks } from 'services/parseUtils';
import { getContentStyle } from 'services/styleUtils/contentStyleUtils';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media screen and (min-width: 992px) {
    margin: 5px 0;
  }
  @media screen and (max-width: 991px) {
    margin: 5px 0;
    padding: 0;
  }
`;

const PlanBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  @media screen and (min-width: 1200px) {
    width: 80%;
    padding: 0;
  }
  @media screen and (min-width: 992px) {
    width: 90%;
    padding: 0;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

const KitCardWrapper = styled(KitCard)`
  height: 100%;
  min-width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    width: 100% !important;
  }
  @media screen and (min-width: 992px) {
    width: 95% !important;
  }
`;

const Callout = styled.div`
  display: block;
  line-height: 1.5rem;
  font-weight: 600;
  position: absolute;
  top: -28px;
  background-color: #cf4520;
  color: white;
  z-index: 101;
  border-radius: 4px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    width: 20rem;
    font-size: 0.89rem;
    right: 15px;
    padding: 10px 5px;
  }
  @media screen and (min-width: 992px) {
    width: 21.5rem;
    font-size: 0.938rem;
    right: 20px;
    padding: 10px 10px;
  }
`;

const OnSiteFrame = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 991px) {
    align-items: center;
    justify-content: center;
  }
`;

const OnSiteContainer = styled.div`
\  border-radius: 10px;
  margin: auto auto;
  @media screen and (min-width: 992px) {
    width: 100%;
    min-height: 310px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: 240px;
    background-size: cover;
    background-position: left 90% bottom;
    background-repeat: no-repeat;
    position: relative;
  }
`;

const OnSiteContentContainer = styled.div`
  display: flex;
  @media screen and (min-width: 992px) {
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 50px;
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    height: 100%;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin-left: 10px;
  }
`;

const PlanHeader = styled.div`
  @media screen and (min-width: 992px) {
    margin: 1.5rem 0 1.25rem 0;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const MobilePlanHeader = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 991px) {
    margin: 1.25rem 0 1rem 0;
  }
`;

const PlanDescription = styled.div`
  @media screen and (min-width: 992px) {
    width: 50%;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const MobilePlanDescription = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 991px) {
    width: 95%;
  }
`;

const OnSiteButtonContainer = styled.div`
  @media screen and (min-width: 992px) {
    margin-top: 35px;
    align-items: center;
    gap: 10px 5px;
    width: 60%;
  }
  @media screen and (max-width: 991px) {
    margin-top: 10px;
    align-items: center;
    gap: 10px 5px;
    width: 90%;
  }
`;

const VirtualCard = styled.div`
  border-radius: 10px;
  @media screen and (min-width: 992px) {
    min-width: 95%;
  }
  @media screen and (max-width: 991px) {
    max-width: 95%;
    margin: 0 auto;
  }
`;

const ARLO_BASE_URL = process.env.ARLO_BASE_URL ?? 'https://boystown.arlo.co/api/2012-02-01/pub/resources/eventsearch/?region=LED';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CommonSenseParentingArloList = ({ module }) => {
  const { fields } = module;

  const theme = useTheme();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const matchesLg = useMediaQuery('screen and (min-width: 992px)');

  const [showDialog, setShowDialog] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [benefits, setBenefits] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [seriesSelected, setSeriesSelected] = useState(null);
  const [virtualSessions, setVirtualSessions] = useState([]);
  const [holdDesc, setHoldDesc] = useState('');

  const [sessionList, setSessionList] = useState([]);

  useEffect(() => {
    setSessionList([]);
    const fetchARLOSessions = async (contentID, id) => {
      await loadArloSessions(id, contentID);
    };

    const loadArloSessions = async (id, contentID) => {
      let sessions = [];
      let url = ARLO_BASE_URL;
      url +=
        '&fields=EventID,Name,AdvertisedOffers,Sessions.StartDateTime,Sessions.Location,Sessions.EndDateTime,Sessions.Summary,RegistrationInfo,Categories,Presenters.Profile,TimeZone,Notice,Description';
      url += `&filter=templatecategoryid%3D${id}`;
      //TESTING TLR url += `&filter=templatecategoryid%3D${1}`;
      url += '&top=200';
      url += '&sort=Sessions.StartDateTime';

      try {
        setIsLoading(true);
        const res = await fetch(url);
        if (res.status === 200) {
          const result = await res.json();
          //const result = mockResp;
          //const result = { Items: [] };
          for (let i = 0; i < result.Items?.length; i++) {
            const item = result.Items[i];
            item.Sessions.forEach((session, idx) => {
              const startDT = DateTime.fromISO(session.StartDateTime).setZone(TZ_MAP.get(item.TimeZone));
              const endDT = DateTime.fromISO(session.EndDateTime).setZone(TZ_MAP.get(item.TimeZone));
              sessions.push({
                registerUri: item.RegistrationInfo?.RegisterUri,
                registerMessage: item.RegistrationInfo?.RegisterMessage,
                time: `${startDT.toFormat('h:mm')} - ${endDT.toFormat('h:mm ZZZZ')}`,
                date: `${startDT.toFormat('LLL d')} - ${endDT.toFormat('LLL d yyyy')}`,
                notice: item.Notice,
                price: item.AdvertisedOffers[0]?.OfferAmount?.FormattedAmountTaxExclusive,
                closeDate: startDT.minus({ days: 14 }).toFormat('LLL d, yyyy'),
                contentID: contentID,
                id: id,
              });
            });
          }
          setIsLoading(false);

          setSessionList((prevItems) => [...prevItems, ...sessions]);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(ERROR_MESSAGES.Default);
        logClientException(error);
      }
    };

    if (fields?.trainingPlans?.length > 0) {
      fields.trainingPlans.forEach((plan) => {
        if (plan.fields.aRLOCategoryId > 0) {
          fetchARLOSessions(plan.contentID, plan.fields.aRLOCategoryId);
        }
      });
    }
  }, [fields.trainingPlans]);

  if (fields?.trainingPlans?.length <= 0) {
    return <NoResourcesContainer></NoResourcesContainer>;
  }

  const handleNoSessionsContactUsClick = async () => {
    router.push(`/contact-us?product=${holdDesc}&trainingMethod=instructor-led virtual`);
  };

  const handleOnSiteContactUs = (planname) => {
    router.push(`/contact-us?product=${planname}&trainingMethod=on-site personalized training`);
  };

  const getTrainingSessions = () => {
    return (
      <Container>
        {fields &&
          fields.trainingPlans?.map((plan, index) => {
            return (
              <Box
                key={index}
                sx={{
                  minWidth: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: '30px',
                }}
              >
                <PlanBox sx={{ maxWidth: { xs: '100%', lg: '1200px' } }}>
                  <div key={index} style={{ width: '100%' }}>
                    {displayTrainingPlan(plan)}
                  </div>
                </PlanBox>
              </Box>
            );
          })}
      </Container>
    );
  };

  const displayTrainingPlan = (plan) => {
    const isOnSite = plan.fields.title.substring(0, 7).toLowerCase() === 'on-site';
    const contentBlocks = parseBlocks(plan.fields?.contentText);
    const contentStyle = getContentStyle(theme, plan.fields?.contentStyle?.fields);

    return isOnSite ? (
      <OnSiteCard
        theme={theme}
        contentStyle={contentStyle}
        contentBlocks={contentBlocks}
        plan={plan}
        learnMore={learnMore}
        handleOnSiteContactUs={handleOnSiteContactUs}
      />
    ) : (
      <VirtualPlanCard plan={plan} fields={fields} sessionList={sessionList} learnMore={learnMore} />
    );
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const learnMore = (contentID) => {
    const plan = fields.trainingPlans && fields.trainingPlans.find((p) => p.contentID === contentID);
    if (plan) {
      setProductImage(plan.fields.learnMoreHeaderImage && plan.fields.learnMoreHeaderImage.url);
      setTitle(plan.fields.learnMoreTitle);
      setDescription(plan.fields.learnMoreDescription);
      setIntroduction(plan.fields.learnMoreIntroduction);
      setBenefits(plan.fields.learnMoreBenefits);
      setDisclaimer(plan.fields.learnMoreDisclaimer);
      setShowDialog(true);
    }
  };

  return (
    <>
      <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <PleaseWait isLoading={isLoading} />
      <GrayContainer>
        <Box
          id='coaching-plan-cards'
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            maxWidth: { sm: '540px', md: '768px', lg: '1140px', xl: '1180px' },
          }}
        >
          <Frame>
            <a id='coaching-plan-cards'></a>
          </Frame>
          <DialogWithX
            size='lg'
            content={
              <PlanOptionLearnMore
                headerImage={productImage}
                title={title}
                description={description}
                introduction={introduction}
                benefits={benefits}
                disclaimer={disclaimer}
                showCalculator={false}
              />
            }
            ariaLabel='Learn More Dialog'
            showValue={showDialog}
            showMethod={setShowDialog}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            pb: '30px',
            pl: '10px',
            pr: '10px',
            maxWidth: { sm: '600px', md: '900px', lg: '1200px', xl: '1500px' },
          }}
        >
          <div id='virtual-training-session-list'>{getTrainingSessions()}</div>
        </Box>
      </GrayContainer>
    </>
  );
};

export default CommonSenseParentingArloList;

const GrayContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.down('md')]: {
    margin: '-35px 0 0 0',
  },
}));

const NoResourcesContainer = styled('div')(({ theme }) => ({
  minHeight: 50,
}));

const Frame = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CardHeader = styled('div')(({ theme }) => ({
  borderRadius: '10px 10px 0px 0px',
  display: 'flex',
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '117px',
    padding: '0.5rem 1.875rem',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0.5rem',
  },
}));

const ResourceType = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 600,
  textTransform: 'uppercase',
  textAlign: 'center',
  marginTop: 10,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
  },
}));

const ResourceName = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 300,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.35rem',
    lineHeight: '1.60rem',
    margin: '10px 0',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.10rem',
    lineHeight: '1.30rem',
    margin: '15px 0',
  },
}));

const Description = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 300,
  fontSize: '1.10rem',
  lineHeight: '1.5rem',
  marginTop: '15px',
  textAlign: 'center',
  height: '30%',
}));

const Title = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  height: '21px',
  fontSize: '25px',
  fontWeight: 600,
  lineHeight: '24px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    margin: '10px 0',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    width: '100%',
    margin: '0 0 5px 0',
  },
}));

const SessionLength = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '26px',
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    lineHeight: '28px',
    height: '28px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: '5px',
    lineHeight: '28px',
    height: '35px',
  },
}));

const Price = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '31px',
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.up('md')]: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
}));

const LearnMore = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  height: '33px',
  fontSize: '31px',
  fontWeight: 400,
  lineHeight: '24px',
}));

const DynamicsTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 400,
  lineHeight: '24px',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    fontSize: '18px',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    fontSize: '17px',
    textAlign: 'center',
    marginBottom: '15px',
  },
}));

const ActionButtonContainer = styled('div')(({ theme }) => ({
  margin: '20px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '10px 5px',
}));

const SessionDates = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    fontWeight: '800',
    lineHeight: '25px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '22px',
  },
}));

const MainHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    width: '110%',
    flexDirection: 'row',
    padding: '20px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '20px',
    width: '100%',
    flexDirection: 'column',
  },
}));

const LeftSideHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

const LeftSideTopHeader = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const PlanNameHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    width: '75%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LengthHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '25%',
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

const LearnMoreButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const LearnMoreButtonMobileContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'underline',
    color: theme.palette.common.white,
    fontSize: '14px',
  },
}));

const VirtualPlanCard = ({ plan, fields, sessionList, learnMore }) => {
  return (
    <VirtualCard>
      <KitCardWrapper
        style={{
          width: '100%',
        }}
      >
        <CardHeader>
          {plan.fields.callout && plan.fields.callout.length > 0 && <Callout>{plan.fields.callout}</Callout>}
          <MainHeader>
            <LeftSideHeader>
              <LeftSideTopHeader>
                <PlanNameHeader>
                  <Title>{plan?.fields?.title}</Title>
                </PlanNameHeader>
                <LengthHeader>
                  <SessionLength style={{ width: '100%', margin: '15px 0' }}>{plan?.fields?.subtitle}</SessionLength>
                </LengthHeader>
              </LeftSideTopHeader>
              <DynamicsTitle>{plan.fields.dynamicsCampaignName}</DynamicsTitle>
            </LeftSideHeader>
            <Price>
              {sessionList && sessionList.length > 0 && sessionList.filter((s) => s.contentID === plan.contentID).length > 0 && plan.fields.price}
            </Price>
            <LearnMoreButtonContainer>
              <KitButton
                color='transparent'
                round
                size='sm'
                onClick={(e) => learnMore(plan.contentID)}
                style={{ border: '2px solid #fff', color: '#fff' }}
              >
                Learn More
              </KitButton>
            </LearnMoreButtonContainer>
            <LearnMoreButtonMobileContainer>
              <KitButton variant='link' onClick={(e) => learnMore(plan.contentID)} style={{ color: '#fff' }}>
                Learn More
              </KitButton>
            </LearnMoreButtonMobileContainer>
          </MainHeader>
        </CardHeader>
        <KitCardBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0' }}>
          <div
            style={{
              backgroundColor: 'transparent',
              margin: '10px 0',
              padding: 0,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2.00em',
            }}
          >
            {sessionList &&
              sessionList.length > 0 &&
              sessionList
                .filter((s) => s.contentID === plan.contentID)
                .map((session, idx) => {
                  return (
                    <Box
                      key={session.aRLOCategoryId}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '300px',
                      }}
                    >
                      <KitGridItem xs={12} md={4}>
                        <Box>
                          <Heading6 sx={{ marginBottom: '10px' }}>{session.date}</Heading6>
                          <BodyCopySmall>{session.time}</BodyCopySmall>
                          <BodyCopySmall>{session.notice}</BodyCopySmall>
                          <BodyCopySmall>Registration closes on {session.closeDate}</BodyCopySmall>
                          <a href={session.registerUri} target='_blank' rel='noreferrer'>
                            <KitButton color='success' round size='sm' style={{ marginTop: '10px' }}>
                              {session.registerMessage}
                            </KitButton>
                          </a>
                        </Box>
                      </KitGridItem>
                    </Box>
                  );
                })}
            {sessionList && sessionList.length > 0 && sessionList.filter((s) => s.contentID === plan.contentID).length === 0 && (
              <KitGridContainer align='center' style={{ margin: '40px 0 5px 0' }}>
                <KitGridItem xs={12} align='center'>
                  {fields && fields.noSessionsMessage && fields.noSessionsMessage.length > 0
                    ? fields.noSessionsMessage
                    : 'We didn’t find any upcoming sessions. Please contact us for additional scheduling options.'}
                </KitGridItem>
                <KitGridItem xs={12} align='center' style={{ marginTop: 20 }}>
                  <KitButton color='success' round onClick={() => handleNoSessionsContactUsClick()} style={{ marginBottom: '20px' }}>
                    Contact Us
                  </KitButton>
                </KitGridItem>
              </KitGridContainer>
            )}
          </div>
        </KitCardBody>
      </KitCardWrapper>
    </VirtualCard>
  );
};

const OnSiteCard = ({ theme, contentStyle, contentBlocks, plan, learnMore, handleOnSiteContactUs }) => {
  return (
    <OnSiteContainer
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 85, 128, 1), rgba(0, 82, 124, 0.95), rgba(2, 73, 110, 0.77), rgba(7, 30, 49, 0)), url(${plan.fields?.backgroundImage.url})`,
      }}
    >
      <OnSiteFrame>
        <OnSiteContentContainer>
          <PlanHeader style={{ color: headingColor(theme, plan.fields?.headingStyle?.fields?.color) }}>
            <FreeformHeader
              level={plan.fields?.headingLevel || 'h4'}
              text={plan.fields?.headingText}
              color={headingColor(theme, plan.fields?.headingStyle?.fields?.color)}
              style={{ ...headingStyle(plan.fields?.headingStyle?.fields?.alignment) }}
            />
          </PlanHeader>
          <MobilePlanHeader style={{ color: headingColor(theme, plan.fields?.headingStyle?.fields?.color) }}>
            <FreeformHeader
              level={'h6'}
              text={plan.fields?.headingText}
              color={headingColor(theme, plan.fields?.headingStyle?.fields?.color)}
              style={{ ...headingStyle(plan.fields?.headingStyle?.fields?.alignment) }}
            />
          </MobilePlanHeader>
          <PlanDescription
            style={{
              color: contentStyle.textColor,
              textAlign: contentStyle.alignment,
              fontSize: contentStyle.fontSize,
              lineHeight: contentStyle.lineHeight,
            }}
          >
            <Freeform blocks={contentBlocks}></Freeform>
          </PlanDescription>
          <MobilePlanDescription
            style={{
              color: contentStyle.textColor,
              textAlign: contentStyle.alignment,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            <Freeform blocks={contentBlocks}></Freeform>
          </MobilePlanDescription>
          <OnSiteButtonContainer>
            <KitButton
              round
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, plan.fields?.buttonColor),
                border: '1px solid ' + buttonColor(theme, plan.fields?.buttonBorderColor),
                color: buttonColor(theme, plan.fields?.buttonTextColor),
                marginRight: '1rem',
              }}
              onClick={(e) => handleOnSiteContactUs(plan.fields?.dynamicsCampaignName)}
            >
              {plan.fields?.contactUsButtonText}
            </KitButton>
            <KitButton
              round
              size='sm'
              style={{
                backgroundColor: buttonColor(theme, plan.fields?.buttonColor),
                border: '1px solid ' + buttonColor(theme, plan.fields?.buttonBorderColor),
                color: buttonColor(theme, plan.fields?.buttonTextColor),
                marginRight: '1rem',
              }}
              onClick={(e) => learnMore(plan.contentID)}
            >
              {plan.fields?.learnMoreButtonText}
            </KitButton>
          </OnSiteButtonContainer>
        </OnSiteContentContainer>
      </OnSiteFrame>
    </OnSiteContainer>
  );
};
