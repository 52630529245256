import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import styled from '@emotion/styled';
import AgilityContentFetch from '@agility/content-fetch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { KitGridContainer, KitGridItem } from '@boystownorg/bi-cms-component-lib';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOut, useSession } from 'next-auth/react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PleaseWait from './PleaseWait';
import { isBoysTownAdmin, ROLE_CONST } from '../../services/roleUtils';
import { parseQueryString } from '../../services/stringUtils';
import YesNoDialog from './subcomponents/YesNoDialog';
import { COMMON_ROUTES, ERROR_MESSAGES, PRODUCTS } from '../../services/constants';
import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException, logServerException } from 'appinsights/clientAppInsights';
import { generateUUID } from '../../services/stringUtils';
import { clientSideLogout } from 'services/authClientUtils';
import { hasModuleAccess, isCustomer } from 'services/subscriptionUtils';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { clearSessionStore, getSessionStoreString, removeSigningOutState, removeSessionStoreItem, setSigningOutState } from 'services/storageUtils';
import { relative } from 'path';
import {
  SharedHeaderDesktop,
  SharedHeaderDesktopWrapper,
  SharedHeaderNav,
  SharedHeaderNavMobile,
} from './SiteHeaderComponents/SiteHeaderStyledComponents';
import SearchButton from './SiteHeaderComponents/SearchButton';
import useSearchContext from './SiteHeaderComponents/SearchContext';
import SearchBar from './SiteHeaderComponents/SearchBar';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { canAccessObservations } from 'components/agility-pageModules/observation/utils/ObservationUtils';
import DOMPurify from 'dompurify';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
  }
`;

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const SiteHeader = ({ globalData, sitemapNode, page, handleHeaderClick }) => {
  const { header } = globalData;

  const isSearchOpen = useSearchContext((s) => s.openMenu) === 'search';
  const toggleSearchOpen = useSearchContext((s) => s.toggle);
  const { setSearchCategory } = useSearchContext();

  // open / close mobile nav
  const [open, setOpen] = useState(false);
  const [openProfileDialoge, setOpenProfileDialog] = useState(false);
  const [openExpiredTrial, setOpenExpiredTrial] = useState(false);
  const [expiredSub, setExpiredSub] = useState({
    open: false,
    product: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [headerLinks, setHeaderLinks] = useState([]);
  const [anchorElResourceMenu, setAnchorElResourceMenu] = React.useState(null);
  const [anchorElProfessionalLearningMenu, setAnchorElProfessionalLearningMenu] = React.useState(null);
  const [anchorElCoachingMenu, setAnchorElCoachingMenu] = React.useState(null);
  const [anchorElLoginMenu, setAnchorElLoginMenu] = React.useState(null);
  const [menuInd, setMenuInd] = useState('');
  const [searchVal, setSearchVal] = useState('');

  const router = useRouter();
  const searchParams = useSearchParams();

  const { data: session, status, update: sessionUpdate } = useSession();

  const resourceOpen = Boolean(anchorElResourceMenu);
  const professionalLearningOpen = Boolean(anchorElProfessionalLearningMenu);
  const coachingOpen = Boolean(anchorElCoachingMenu);
  const loginOpen = Boolean(anchorElLoginMenu);

  const handleResourceMenuClose = (path) => {
    setAnchorElResourceMenu(null);
    if (path && typeof path === 'string') {
      router.push(path);
    }
    setOpen(false);
  };

  const openResourceInNewTab = (path) => {
    setAnchorElResourceMenu(null);
    if (path && typeof path === 'string') {
      window.open(path, '_blank');
    }
    setOpen(false);
  };

  const handleProfessionalLearningMenuClose = (path) => {
    setAnchorElProfessionalLearningMenu(null);
    if (path && typeof path === 'string') {
      router.push(path);
    }
    setOpen(false);
  };

  const handleCoachingMenuClose = (path) => {
    setAnchorElCoachingMenu(null);
    if (path && typeof path === 'string') {
      router.push(path);
    }
    setOpen(false);
  };

  const handleSingleLink = (path) => {
    if (path && typeof path === 'string') {
      router.push(path);
    }
    setOpen(false);
  };

  const handleLoginMenuClose = (path) => {
    setAnchorElLoginMenu(null);
    if (path && typeof path === 'string') {
      router.push(path);
    }
    setOpen(false);
  };

  const searchFormSubmit = (e) => {
    e.preventDefault();
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.set('q', searchVal);
    nextSearchParams.delete('p');
    router.push(`/search?${nextSearchParams}`);
    setSearchVal('');
    setOpen(false);
  };

  // this useEffect checks if the user is trying to
  // navigate away from the page without completing their profile
  // note: I don't like this approach and would prefer to use a modal
  useEffect(() => {
    const confirmationMessage = 'Please complete your profile before visiting any other areas of the site!';
    const beforeUnloadHandler = (e) => {
      if (window.location.pathname === '/complete-profile' && getSessionStoreString('profile_complete') !== 'true') {
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    const handleRouteChange = (url, { shallow }) => {
      if (window.location.pathname === '/complete-profile' && getSessionStoreString('profile_complete') !== 'true') {
        router.events.emit('routeChangeError');
        alert(confirmationMessage);
        throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
      }
    };

    if (openProfileDialoge) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
      router.events.on('routeChangeStart', handleRouteChange);
      router.events.on('beforeHistoryChange', handleRouteChange);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('beforeHistoryChange', handleRouteChange);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [router, openProfileDialoge]);

  useEffect(() => {
    if (status === 'authenticated') {
      if (isCustomer(session?.user)) {
        setMenuInd('is_customer');
      } else {
        setMenuInd('not_customer');
      }
    } else if (status === 'unauthenticated') {
      setMenuInd('not_customer');
    } else {
      setMenuInd(null);
    }
  }, [status, session]);

  // useEffect(() => {
  //   if (window.sessionStorage.getItem('signing_in') === 'true') {
  //     if (session?.user) {
  //       const event = {
  //         'event': 'login',
  //         'userId': session.user.id,
  //         'user_profession': session.user.position,
  //         'user_email': session.user.email,
  //       };
  //       addDataLayerEvent(event);
  //     }
  //     if (window && window.sessionStorage.getItem('redirect') && window.sessionStorage.getItem('redirect').startsWith('/')) {
  //       router.push(window.sessionStorage.getItem('redirect'));
  //       window.sessionStorage.removeItem('redirect');
  //       sessionStorage.removeItem('signing_in');
  //     } else if (session && window.location.pathname === '/') {
  //       if (hasModuleAccess(session, 'wms')) {
  //         router.push('/well-managed-schools');
  //       } else if (hasModuleAccess(session, 'ai')) {
  //         router.push('/administrative-intervention');
  //       } else if (hasModuleAccess(session, 'shss')) {
  //         router.push('/safe-healthy-secondary-schools');
  //       } else if (hasModuleAccess(session, 'scm')) {
  //         router.push('/specialized-classroom-management');
  //       } else if (hasModuleAccess(session, 'pas')) {
  //         router.push('/positive-alternatives-to-suspension');
  //       } else if (hasModuleAccess(session, 'explit')) {
  //         router.push('/expedition-literacy');
  //       } else {
  //         router.push('/free-resources');
  //       }
  //       sessionStorage.removeItem('signing_in');
  //     }
  //   }
  // }, [session, router]);

  useEffect(() => {
    console.log('Running National Training version 1.04.02.');
    if (!session) {
      setHeaderLinks(header.links);
    } else {
      let tempLinks = [];
      header.links.forEach((link) => {
        tempLinks.push(link);
      });
      setHeaderLinks(tempLinks);
    }
  }, [header.links, session, router]);

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError') {
      doSignin(); // Force sign in to hopefully resolve error
    }

    let needsProfile = false;

    if (session?.user) {
      // user is logged in

      if (!session?.user?.state) {
        // state not set
        needsProfile = true;
      } else if (!session?.user?.position) {
        //position not set
        needsProfile = true;
      } else if (session.user.role === ROLE_CONST.BoysTownAdmin || session.user.role === ROLE_CONST.ContentReviewer) {
        // no subscription necessary for admin
      } else if (!session.user.subscriptions || session.user.subscriptions.length === 0) {
        // no current subscription
        needsProfile = true;
      } else if (session.user.subscriptions && session.user.subscriptions.filter((s) => s?.message === ERROR_MESSAGES.TrialExpired)?.length > 0) {
        // have an expired subscription
        console.log('has expired trial sub');
        setOpenExpiredTrial(true);
      } else if (
        session.user.subscriptions &&
        session.user.subscriptions.filter((s) => s?.message === ERROR_MESSAGES.SubscriptionExpired)?.length > 0 &&
        typeof window !== 'undefined' &&
        window.sessionStorage.getItem('renewing') !== 'true'
      ) {
        // have an expired subscription
        console.log('has expired sub');
        const expiredSub = session.user.subscriptions.filter((s) => s?.message === ERROR_MESSAGES.SubscriptionExpired)[0];
        setExpiredSub({ open: true, product: PRODUCTS.find((p) => p.id === expiredSub.product).name, sub: expiredSub });
      } else if (session.user.subscriptions && session.user.subscriptions.filter((s) => s?.is_active === true)?.length > 0) {
        // have a current subscription
      } else if (session.user.subscriptions && session.user.subscriptions.filter((s) => s?.is_active === true)?.length === 0) {
        // no subscription
        needsProfile = true;
      }

      if (needsProfile) {
        setOpenProfileDialog(true);
      } else {
        removeSessionStoreItem('profile_complete');
      }

      if (window.sessionStorage.getItem('signing_in') === 'true') {
        if (session?.user) {
          const event = {
            'event': 'login',
            'userId': session.user.id,
            'user_profession': session.user.position,
            'user_email': session.user.email,
          };
          addDataLayerEvent(event);
        }
        if (needsProfile) {
          // no redirects - should go to complete profile
        } else if (window && window.sessionStorage.getItem('redirect') && window.sessionStorage.getItem('redirect').startsWith('/')) {
          const sanitizedRedirect = DOMPurify.sanitize(window.sessionStorage.getItem('redirect'));
          router.push(sanitizedRedirect);
          window.sessionStorage.removeItem('redirect');
          sessionStorage.removeItem('signing_in');
        } else if (session && window.location.pathname === '/') {
          if (hasModuleAccess(session, 'wms')) {
            router.push('/well-managed-schools');
          } else if (hasModuleAccess(session, 'ai')) {
            router.push('/administrative-intervention');
          } else if (hasModuleAccess(session, 'shss')) {
            router.push('/safe-healthy-secondary-schools');
          } else if (hasModuleAccess(session, 'scm')) {
            router.push('/specialized-classroom-management');
          } else if (hasModuleAccess(session, 'pas')) {
            router.push('/positive-alternatives-to-suspension');
          } else if (hasModuleAccess(session, 'explit')) {
            router.push('/expedition-literacy');
          } else {
            router.push('/free-resources');
          }
          sessionStorage.removeItem('signing_in');
        }
      }
    }
  }, [session]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window?.location?.search) {
        //https://ntr-app-tst-01.azurewebsites.net?did=93f0ddbb-3f00-4f60-a832-81b76cbcfee4&st=NE
        const qs = parseQueryString(window?.location?.search);
        if (qs?.did) {
          window?.localStorage?.setItem('did', qs.did);
        }
        if (qs?.st) {
          window?.localStorage?.setItem('st', qs.st);
        }
        if (qs?.sid) {
          window?.localStorage?.setItem('sid', qs.sid);
        }
        if (qs?.utm_source) {
          window?.sessionStorage?.setItem('utm_source', qs.utm_source);
        }
        if (qs?.utm_medium) {
          window?.sessionStorage?.setItem('utm_medium', qs.utm_medium);
        }
        if (qs?.utm_campaign) {
          window?.sessionStorage?.setItem('utm_campaign', qs.utm_campaign);
        }
        if (qs?.utm_content) {
          window?.sessionStorage?.setItem('utm_content', qs.utm_content);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.href.indexOf('/complete-profile') === -1 && openProfileDialoge) {
      router.push('/complete-profile');
    }
  }, [openProfileDialoge]);

  const doSignin = () => {
    removeSigningOutState();
    router.push('/signin');
  };

  const doSignOut = async () => {
    setIsLoading(true);
    clearSessionStore();
    setSigningOutState();
    if (session && session.logout_url) {
      const parsedUrl = new URL(session.logout_url);
      const allowedHosts = ['b2clogin.com'];
      if (allowedHosts.find((h) => parsedUrl.host.indexOf(h) > -1)) {
        // force clearing the b2c signin session.
        await signOut({ redirect: false });
        await clientSideLogout(session);
      } else {
        signOut({ callbackUrl: `${window.location.origin}/` });
      }
    } else {
      signOut({ callbackUrl: `${window.location.origin}/` });
    }
  };

  if (!header) {
    return <header></header>;
  }

  let navLinks = [];
  let navMobileLinks = [];
  // let navLinks = header.links.map((navitem, index) => {
  //   return (
  //     <NavListItem key={`mobile-${index}`}>
  //       <a href={navitem.path}>
  //         <LinkTitle>{navitem.title}</LinkTitle>
  //       </a>
  //     </NavListItem>
  //   );
  // });

  // let navMobileLinks = header.links.map((navitem, index) => {
  //   return (
  //     // <Link >
  //     <a key={`nav-${index}`} href={navitem.path} onClick={() => setOpen(false)}>
  //       <MobileLinkTitle>{navitem.title}</MobileLinkTitle>
  //     </a>
  //     // </Link>
  //   );
  // });

  const onUpgradeSubscription = () => {
    setOpenExpiredTrial(false);
    router.push(COMMON_ROUTES.PricingPage);
  };

  const continueWithFreeAccount = async () => {
    await addFreeSubscription();
  };

  const addFreeSubscription = async () => {
    try {
      setIsLoading(true);
      let body = {
        emailAddress: session.user.email,
        product: 'free',
        status: 'active',
        end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 99)).getTime(),
        id: generateUUID(),
      };

      // save user
      await callApi('/api/db/user-subscription', 'POST', body);
      setIsLoading(false);
      setOpenProfileDialog(false);
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    } catch (error) {
      logClientException(error);
      setIsLoading(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const onRenewSubscription = () => {
    setExpiredSub({ open: false });
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('renewing', 'true');
    }
    router.push(COMMON_ROUTES.PricingPage);
  };

  const continueWithExpiredProduct = async () => {
    try {
      setIsLoading(true);
      let body = { emailAddress: session.user.email, status: 'expired', sub_id: expiredSub.sub.id };

      // update subscription
      await callApi('/api/db/user-subscription', 'PUT', body);
      if (!session.user.subscriptions?.find((s) => s.product === 'free')) {
        await addFreeSubscription();
      }
      setIsLoading(false);
      setExpiredSub({ open: false, product: '', sub: null });
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    } catch (error) {
      logClientException(error);
      setIsLoading(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
        return;
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (menuInd === 'is_customer') {
    const pricingLink = navLinks.findIndex((l) => l.key === 'pricingMenuLink');
    // Remove Pricing Link
    if (pricingLink > -1) {
      navLinks.splice(pricingLink, 1);
    }
    navLinks.unshift(
      <div key='mylearningMenuLink'>
        <LinkTitle id='mylearningMenuLink' onClick={(e) => handleSingleLink('/well-managed-schools')}>
          My Learning
        </LinkTitle>
      </div>
    );
    navMobileLinks.unshift(
      <div key='mylearningMobileMenuLink'>
        <MobileLinkTitle id='mylearningMobileMenuLink' onClick={(e) => handleSingleLink('/well-managed-schools')}>
          My Learning
        </MobileLinkTitle>
      </div>
    );
  } else {
    const learningLink = navLinks.findIndex((l) => l.key === 'mylearningMenuLink');
    // Remove My Learning Link
    if (learningLink > -1) {
      navLinks.splice(learningLink, 1);
    }
    navLinks.unshift(
      <div key='pricingMenuLink'>
        <LinkTitle id='pricingMenuLink' onClick={(e) => handleSingleLink('/plan-options')}>
          Pricing
        </LinkTitle>
      </div>
    );
    navMobileLinks.unshift(
      <div key='pricingMobileMenuLink'>
        <MobileLinkTitle id='pricingMobileMenuLink' onClick={(e) => handleSingleLink('/plan-options')}>
          Pricing
        </MobileLinkTitle>
      </div>
    );
  }

  navLinks.unshift(
    <div key='resourceMenuLink'>
      <LinkTitle id='resourceMenuLink' onClick={(e) => setAnchorElResourceMenu(e.currentTarget)}>
        Educator Resources <ArrowDropDownIcon />
      </LinkTitle>
    </div>
  );
  navMobileLinks.unshift(
    <div key='resourceMobileMenuLink'>
      <MobileLinkTitle id='resourceMobileMenuLink' onClick={(e) => setAnchorElResourceMenu(e.currentTarget)}>
        Educator Resources <ArrowDropDownIcon />
      </MobileLinkTitle>
    </div>
  );

  navLinks.unshift(
    <div key='coachingMenuLink'>
      <LinkTitle id='professionalLearningMenuLink' onClick={(e) => setAnchorElCoachingMenu(e.currentTarget)}>
        Coaching & More <ArrowDropDownIcon />
      </LinkTitle>
    </div>
  );
  navMobileLinks.unshift(
    <div key='coachingMobileMenuLink'>
      <MobileLinkTitle id='professionalLearningMobileMenuLink' onClick={(e) => setAnchorElCoachingMenu(e.currentTarget)}>
        Coaching & More <ArrowDropDownIcon />
      </MobileLinkTitle>
    </div>
  );

  navLinks.unshift(
    <div key='professionalLearningMenuLink'>
      <LinkTitle id='professionalLearningMenuLink' onClick={(e) => setAnchorElProfessionalLearningMenu(e.currentTarget)}>
        Professional Learning <ArrowDropDownIcon />
      </LinkTitle>
    </div>
  );
  navMobileLinks.unshift(
    <div key='professionalLearningMobileMenuLink'>
      <MobileLinkTitle id='professionalLearningMobileMenuLink' onClick={(e) => setAnchorElProfessionalLearningMenu(e.currentTarget)}>
        Professional Learning <ArrowDropDownIcon />
      </MobileLinkTitle>
    </div>
  );

  navLinks.push(
    <NavListItem key='loginMenuLink'>
      <LinkTitle id='loginLink' onClick={(e) => setAnchorElLoginMenu(e.currentTarget)}>
        {session ? (
          <>
            <UserGreeting>{session.user.firstName}</UserGreeting> <ArrowDropDownIcon />
          </>
        ) : (
          <AccountCircleIcon style={{ marginTop: '-2px' }} />
        )}
      </LinkTitle>
    </NavListItem>
  );
  navMobileLinks.push(
    <div key='loginMenuMobileLink'>
      <MobileLinkTitle id='loginMobileLink' onClick={(e) => setAnchorElLoginMenu(e.currentTarget)}>
        {session ? (
          <>
            <UserGreeting>{session.user.firstName}</UserGreeting> <ArrowDropDownIcon />
          </>
        ) : (
          <>
            Sign In <ArrowDropDownIcon />
          </>
        )}
      </MobileLinkTitle>
    </div>
  );

  return (
    <React.Fragment>
      <PleaseWait isLoading={isLoading} />
      {/* <CompleteProfileDialog
        user={session?.user}
        terms={header.terms}
        open={openProfileDialoge}
        onClose={() => {
          sessionUpdate();
          setOpenProfileDialog(false);
        }}
      /> */}
      <YesNoDialog
        open={openExpiredTrial}
        content='Your trial has expired. Would like to upgrade your subscription?'
        yesClose={onUpgradeSubscription}
        noClose={continueWithFreeAccount}
      />
      <YesNoDialog
        open={expiredSub.open}
        content={`Your "${expiredSub.product}" subscription has expired. Would like to renew your subscription?`}
        yesClose={onRenewSubscription}
        noClose={continueWithExpiredProduct}
      />
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Desktop Menu */}
      <SharedHeaderDesktopWrapper>
        <SharedHeaderDesktop id='shared-header-desktop'>
          <SharedHeaderNav iconUrl={header.sharedHeaderFields.mainIcon?.url}>
            <li>
              <a className='primary-nav-link' href={header.sharedHeaderFields.mainLink?.href} target='_blank' rel='noreferrer'>
                {header.sharedHeaderFields.mainLabel}
              </a>
            </li>
            {header.sharedHeaderFields.sites.map((link, index) => {
              return (
                <li key={`desktop-${index}`}>
                  <a
                    className={link.fields.label?.toLocaleLowerCase() === 'liftwithboystown.org' ? 'current-link' : 'primary-nav-link'}
                    href={link.fields.link.href}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {link.fields.label}
                  </a>
                </li>
              );
            })}
            {/* <li>
              <a className='help-btn' href={header.sharedHeaderFields.getHelpNowLink.href} target='_blank' rel='noreferrer'>
                {header.sharedHeaderFields.getHelpNowLabel}
              </a>
            </li> */}
            <li>
              <SearchButton
                isActive={isSearchOpen}
                onClick={() => {
                  toggleSearchOpen('search');
                  setSearchCategory('');
                }}
              />
            </li>
          </SharedHeaderNav>
        </SharedHeaderDesktop>
        <SearchBar isActive={isSearchOpen} searchTerms={header.searchTerms} />
        <div style={{ position: relative }}>
          <LogoBox background id='desktop-logobox'>
            <KitGridContainer direction='row' id='desktop-logobox-container'>
              <KitGridItem xs={11} align='center' style={{ padding: '10px 10px 10px 15px' }}>
                <Link href='/' id='logobox-home-link'>
                  <img src={header.logo.url} alt={header.logo.label} title={header.logo.siteName} />
                </Link>
              </KitGridItem>
              <KitGridItem xs={4} sm={3} md={2} align='right' style={{ padding: '5px 30px 0 0' }}>
                <ToggleButton className='-mr-2 -my-2'>
                  <button
                    onClick={() => setOpen(!open)}
                    aria-label='Toggle Menu'
                    type='button'
                    className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
                  >
                    ?1
                  </button>
                </ToggleButton>
              </KitGridItem>
            </KitGridContainer>
          </LogoBox>
          <KitGridContainer id='desktop-nav-bar' direction='row' style={{ backgroundColor: '#005581', paddingTop: '19px' }}>
            <KitGridItem xs={12} align='right'>
              <NavMenuBar onDoubleClick={handleHeaderClick}>
                <NavHeaderMenu>
                  <NavList align='right' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <>
                      {navLinks}
                      {!session && (
                        <>
                          <StyledNavMenu
                            id='loginmenu'
                            anchorEl={anchorElLoginMenu}
                            open={loginOpen}
                            onClose={handleLoginMenuClose}
                            MenuListProps={{
                              'aria-labelledby': 'Login Menu',
                            }}
                          >
                            <MenuItem onClick={doSignin} href='/signin' component='a' selected={router.asPath === '/signin'}>
                              Sign In
                            </MenuItem>
                            <MenuItem onClick={doSignin} href='/signin?nl=1' component='a'>
                              Create a Free Account / <br /> Sign Up For Newsletter
                            </MenuItem>
                            <Divider />

                            {canAccessObservations(session, status) && (
                              <MenuItem
                                onClick={() => handleLoginMenuClose('/observation/landing')}
                                href='/observation/landing'
                                component='a'
                                selected={router.asPath === '/observation/landing'}
                              >
                                Data Sweep
                              </MenuItem>
                            )}

                            <MenuItem
                              onClick={() => handleLoginMenuClose('/graduate-credit-request')}
                              href='/graduate-credit-request'
                              component='a'
                              selected={router.asPath === '/graduate-credit-request'}
                            >
                              Graduate Credit
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleLoginMenuClose('/contact-us')}
                              href='/contact-us'
                              component='a'
                              selected={router.asPath === '/contact-us'}
                            >
                              Contact Us
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleLoginMenuClose('/support')}
                              href='/support'
                              component='a'
                              selected={router.asPath === '/support'}
                            >
                              Help
                            </MenuItem>
                          </StyledNavMenu>
                        </>
                      )}
                      {session && (
                        <StyledNavMenu
                          id='loginmenu'
                          anchorEl={anchorElLoginMenu}
                          open={loginOpen}
                          onClose={handleLoginMenuClose}
                          MenuListProps={{
                            'aria-labelledby': 'Login Menu',
                          }}
                        >
                          {isCustomer(session?.user) && (
                            <MenuItem
                              onClick={() => handleSingleLink('plan-options')}
                              href='/plan-options'
                              component='a'
                              selected={router.asPath === '/plan-options'}
                            >
                              Pricing
                            </MenuItem>
                          )}
                          {isBoysTownAdmin(session) && window.screen.width > 768 && (
                            <>
                              <MenuItem
                                onClick={() => handleLoginMenuClose('/admin/manage-users')}
                                href='/admin/manage-users'
                                component='a'
                                selected={router.asPath === '/admin/manage-users'}
                              >
                                Manage Users
                              </MenuItem>
                              {session?.user?.role === ROLE_CONST.BoysTownAdmin && (
                                <>
                                  <MenuItem
                                    onClick={() => handleLoginMenuClose('/admin/domain-allow-list')}
                                    href='/admin/domain-allow-list'
                                    component='a'
                                    selected={router.asPath === '/admin/domain-allow-list'}
                                  >
                                    Email Allow List
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleLoginMenuClose('/admin/coupon-code-list')}
                                    href='/admin/coupon-code-list'
                                    component='a'
                                    selected={router.asPath === '/admin/coupon-code-list'}
                                  >
                                    Coupon Codes
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleLoginMenuClose('/admin/manage-subs')}
                                    href='/admin/manage-subs'
                                    component='a'
                                    selected={router.asPath === '/admin/manage-subs'}
                                  >
                                    Onboarding
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleLoginMenuClose('/admin/feature-switch-list')}
                                    href='/admin/feature-switch-list'
                                    component='a'
                                    selected={router.asPath === '/admin/feature-switch-list'}
                                  >
                                    Feature Switches
                                  </MenuItem>
                                  {/* <MenuItem
                                    onClick={() => handleLoginMenuClose('/admin/manage-vector-store')}
                                    href='/admin/manage-vector-store'
                                    component='a'
                                    selected={router.asPath === '/admin/manage-vector-store'}
                                  >
                                    Manage Vector Store
                                  </MenuItem> */}
                                </>
                              )}
                              <Divider />
                            </>
                          )}

                          {canAccessObservations(session, status) && (
                            <MenuItem
                              onClick={() => handleLoginMenuClose('/observation/landing')}
                              href='/observation/landing'
                              component='a'
                              selected={router.asPath === '/observation/landing'}
                            >
                              Data Sweep
                            </MenuItem>
                          )}

                          <MenuItem
                            onClick={() => handleLoginMenuClose('/graduate-credit-request')}
                            href='/graduate-credit-request'
                            component='a'
                            selected={router.asPath === '/graduate-credit-request'}
                          >
                            Graduate Credit
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleLoginMenuClose('/account')}
                            href='/account'
                            component='a'
                            selected={router.asPath === '/account'}
                          >
                            My Account
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleLoginMenuClose('/contact-us')}
                            href='/contact-us'
                            component='a'
                            selected={router.asPath === '/contact-us'}
                          >
                            Contact Us
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleLoginMenuClose('/support')}
                            href='/support'
                            component='a'
                            selected={router.asPath === '/support'}
                          >
                            Help
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={doSignOut}>Sign Out</MenuItem>
                        </StyledNavMenu>
                      )}
                    </>
                  </NavList>
                </NavHeaderMenu>
              </NavMenuBar>
            </KitGridItem>
          </KitGridContainer>
        </div>
      </SharedHeaderDesktopWrapper>

      {/* Mobile Menu */}
      <MobileLogoBox background>
        <KitGridContainer direction='row'>
          <KitGridItem xs={10} sm={8} md={9} align='left'>
            <LogoFrame>
              <Link href='/' id='logobox-home-link-mobile'>
                <img src={header.logo.url} alt={header.logo.label} title={header.logo.siteName} />
              </Link>
            </LogoFrame>
          </KitGridItem>
          <KitGridItem xs={2} sm={4} md={3} align='right'>
            <ToggleButton id='mobile-toggle-button' style={{ margin: '15px 5px 0 0' }}>
              <button
                onClick={() => setOpen(!open)}
                aria-label='Toggle Menu'
                type='button'
                className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out'
              >
                {/* <!-- Hamburger Menu --> */}
                <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
                </svg>
              </button>
            </ToggleButton>
          </KitGridItem>
        </KitGridContainer>

        {/* Mobile Drop-Down Menu */}
        <NavMobileMenuContainer>
          <NavMobileMenu style={{ display: open ? 'block' : 'none' }}>
            <div className='-mr-2' style={{ display: 'flex', justifyContent: 'space-between', margin: '0 18px 10px 0' }}>
              <form onSubmit={searchFormSubmit}>
                <StyledTextField
                  placeholder='Search'
                  color='primary'
                  fullWidth
                  //sx={{ width: '90%' }}
                  size='small'
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                  InputProps={{
                    sx: { borderRadius: '999px', mt: '1rem' },
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon color='primary' />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end' sx={{ display: searchVal?.length > 0 ? 'flex' : 'none' }}>
                        <IconButton aria-label='clear search' onClick={() => setSearchVal('')}>
                          <CloseIcon color='primary' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
              </form>
              <button onClick={() => setOpen(!open)} aria-label='Toggle Menu' type='button'>
                {/* <!-- Close Drop-down --> */}
                <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                </svg>
              </button>
            </div>
            <>
              {navMobileLinks}

              {/* <SharedHeaderNavMobileTertiary>
                <div id='mobileHelpBtn'>
                  <a className='help-btn' href={header.sharedHeaderFields.getHelpNowLink.href} target='_blank' rel='noreferrer'>
                    {header.sharedHeaderFields.getHelpNowLabel}
                  </a>
                </div>
              </SharedHeaderNavMobileTertiary> */}

              <SharedHeaderNavMobile iconUrl={header.sharedHeaderFields.mainIcon?.url}>
                <li>
                  <a className='primary-nav-link' href={header.sharedHeaderFields.mainLink?.href} target='_blank' rel='noreferrer'>
                    {header.sharedHeaderFields.mainLabel}
                  </a>
                </li>
                {header.sharedHeaderFields.sites.map((link, index) => {
                  return (
                    <li key={`desktop-${index}`}>
                      <a
                        className={link.fields.label?.toLocaleLowerCase() === 'liftwithboystown.org' ? 'current-link' : 'primary-nav-link'}
                        href={link.fields.link.href}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {link.fields.label}
                      </a>
                    </li>
                  );
                })}
              </SharedHeaderNavMobile>
            </>
          </NavMobileMenu>
        </NavMobileMenuContainer>
      </MobileLogoBox>

      {/* Specialized Menus */}
      <StyledNavMenu
        id='resourcement'
        anchorEl={anchorElResourceMenu}
        open={resourceOpen}
        onClose={handleResourceMenuClose}
        MenuListProps={{
          'aria-labelledby': 'Resource Menu Link',
        }}
      >
        <MenuItem
          onClick={() => handleResourceMenuClose('/free-resources')}
          href='/free-resources'
          component='a'
          selected={router.asPath === '/free-resources'}
        >
          Free Resources
        </MenuItem>
        {menuInd === 'is_customer' && (
          <MenuItem
            onClick={() => handleResourceMenuClose('/premium-resources/lesson-plan')}
            href='/premium-resources/lesson-plan'
            component='a'
            selected={router.asPath === '/premium-resources/lesson-plan'}
          >
            Premium Resources
          </MenuItem>
        )}
        <MenuItem onClick={() => handleResourceMenuClose('/blog')} href='/blog' component='a' selected={router.asPath === '/blog'}>
          Blog
        </MenuItem>
        <MenuItem
          onClick={() => handleResourceMenuClose('/ask-the-trainer')}
          href='/ask-the-trainer'
          component='a'
          selected={router.asPath === '/ask-the-trainer'}
        >
          Ask The Trainer
        </MenuItem>
        <MenuItem onClick={() => openResourceInNewTab('https://www.boystownpress.org/')} component='a'>
          Boys Town Press
        </MenuItem>
      </StyledNavMenu>

      <StyledNavMenu
        id='professionallearningmenu'
        anchorEl={anchorElProfessionalLearningMenu}
        open={professionalLearningOpen}
        onClose={handleProfessionalLearningMenuClose}
        MenuListProps={{
          'aria-labelledby': 'Professional Learning Menu',
        }}
      >
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('/well-managed-schools')}
          href='/well-managed-schools'
          component='a'
          selected={router.asPath === '/well-managed-schools'}
        >
          Well-Managed Schools
        </MenuItem>
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('/administrative-intervention')}
          href='/administrative-intervention'
          component='a'
          selected={router.asPath === '/administrative-intervention'}
        >
          Administrative Intervention
        </MenuItem>
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('/safe-healthy-secondary-schools')}
          href='/safe-healthy-secondary-schools'
          component='a'
          selected={router.asPath === '/safe-healthy-secondary-schools'}
        >
          Safe and Healthy Secondary Schools
        </MenuItem>
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('/specialized-classroom-management')}
          href='/specialized-classroom-management'
          component='a'
          selected={router.asPath === '/specialized-classroom-management'}
        >
          Specialized Classroom Management
        </MenuItem>
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('/positive-alternatives-to-suspension')}
          href='/positive-alternatives-to-suspension'
          component='a'
          selected={router.asPath === '/positive-alternatives-to-suspension'}
        >
          Positive Alternatives to Suspension
        </MenuItem>
        <MenuItem
          onClick={() => handleProfessionalLearningMenuClose('expedition-literacy')}
          href='/expedition-literacy'
          component='a'
          selected={router.asPath === '/expedition-literacy'}
        >
          Expedition Literacy
        </MenuItem>
      </StyledNavMenu>

      <StyledNavMenu
        id='coachingmenu'
        anchorEl={anchorElCoachingMenu}
        open={coachingOpen}
        onClose={handleCoachingMenuClose}
        MenuListProps={{
          'aria-labelledby': 'Coaching and More Menu',
        }}
      >
        <MenuItem
          onClick={() => handleCoachingMenuClose('/coaching/coaching-and-consultation')}
          href='/coaching/coaching-and-consultation'
          component='a'
          selected={router.asPath === '/coachine/coaching-and-consultation'}
        >
          Coaching and Consultation
        </MenuItem>
        <MenuItem
          onClick={() => handleCoachingMenuClose('/coaching/common-sense-parenting-training-of-trainers')}
          href='/coaching/common-sense-parenting-training-of-trainers'
          component='a'
          selected={router.asPath === '/coaching/common-sense-parenting-training-of-trainers'}
        >
          Common Sense Parenting Training of Trainers
        </MenuItem>
        <MenuItem
          onClick={() => handleCoachingMenuClose('/coaching/parenting-for-school-success-classes')}
          href='/coaching/parenting-for-school-success-classes'
          component='a'
          selected={router.asPath === '/coaching/parenting-for-school-success-classes'}
        >
          Parenting for School Success Classes
        </MenuItem>
      </StyledNavMenu>
    </React.Fragment>
  );
};

SiteHeader.getCustomInitialProps = async function ({ agility, languageCode, channelName }) {
  // set up api
  const api = agility;

  // set up content item
  let contentItem = null;

  // set up links
  let links = [];
  let terms = null;
  let sharedHeaderFields = {};
  let searchTerms = [];

  try {
    // set up api for content hub
    const hubApi = AgilityContentFetch.getApi({
      guid: process.env.CONTENT_HUB_GUID,
      apiKey: api.config.isPreview ? process.env.CONTENT_HUB_API_PREVIEW_KEY : process.env.CONTENT_HUB_API_FETCH_KEY,
      isPreview: api.config.isPreview,
    });

    const sharedHeader = await hubApi.getContentList({
      referenceName: 'sharedheader',
      locale: languageCode,
      contentLinkDepth: 3,
      expandAllContentLinks: true,
    });

    const { fields } = sharedHeader.items[0] || {};
    sharedHeaderFields = fields;

    // try to fetch our site header
    let header = await api.getContentList({
      referenceName: 'siteheader',
      languageCode: languageCode,
      take: 1,
      contentLinkDepth: 3,
      expandAllContentLinks: true,
    });

    // if we have a header, set as content item
    if (header && header.items && header.items.length > 0) {
      contentItem = header.items[0];
      if (contentItem.fields.searchTerms) {
        searchTerms = contentItem.fields.searchTerms.map((term) => term.fields.textValue);
      }

      // else return null
    } else {
      return null;
    }

    let page = await api.getPage({
      pageID: 100,
      locale: languageCode,
    });

    if (page?.zones?.MainContentZone?.length > 0) {
      const mod = page?.zones?.MainContentZone.find((m) => m.module === 'RichTextArea');
      if (mod?.item?.fields?.textblob) {
        terms = mod?.item?.fields?.textblob;
      }
    }
  } catch (error) {
    await logServerException(error);
    if (console) console.error('Could not load site header item.', error);
    return null;
  }

  try {
    // get the nested sitemap
    let sitemap = await api.getSitemapNested({
      channelName: channelName,
      languageCode: languageCode,
    });

    //console.dir(sitemap)

    // grab the top level links that are visible on menu
    links = sitemap
      .filter((node) => node.visible.menu)
      .map((node) => {
        return {
          title: node.menuText || node.title,
          path: node.path,
        };
      });
  } catch (error) {
    await logServerException(error);
    if (console) console.error('Could not load nested sitemap.', error);
  }

  // return clean object...
  return {
    siteName: contentItem.fields.siteName,
    searchTerms,
    logo: contentItem.fields.logo,
    links,
    terms,
    sharedHeaderFields,
  };
};

export default SiteHeader;

const LogoBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  left: 0,
  zIndex: 10,
  position: 'absolute',
  top: '48px',

  [theme.breakpoints.up('lg')]: {
    height: '96px',
    padding: '15px 0 0 20px',
    maxWidth: '400px',
    zIndex: 200,
    '&:after': {
      content: '""',
      borderLeft: '18px solid #58C1B3',
      borderRight: '18px solid transparent',
      borderBottom: '18px solid transparent',
      height: 0,
      width: 0,
      position: 'absolute',
      left: '400px',
      top: '78px',
      zIndex: 2,
    },
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const MobileLogoBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  left: 0,
  zIndex: 10,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    height: '90px',
    padding: '15px 0 0 0',
    maxWidth: '100%',
  },
}));

const LogoFrame = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: '5px -5px 5px -5px',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '15px 25px 5px 10px',
    maxWidth: '350px',
  },
}));

const MobileMenuButton = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
  color: theme.palette.common.white,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

const NavMobileMenuContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  display: 'flex',
  justifyContent: 'flex-end',
}));

const NavMobileMenu = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  align: 'right',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  opacity: 0.97,
  margin: '-70px -10px 0 10px',
  padding: 5,
  zIndex: 300,
  minWidth: '100%',
  // [theme.breakpoints.up('md')]: {
  //   minWidth: '40%',
  // },
  // [theme.breakpoints.down('md')]: {
  //   minWidth: '60%',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   minWidth: '75%',
  // },
}));

const SpacerBar = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  minWidth: '100%',
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: '#005581',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('lg')]: {
    height: '40px',
    margin: '-118px 5px 0 5px',
    padding: '5px 35px',
    zIndex: 100,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const LoginButton = styled('KitButton')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '3px 23px',
  margin: 5,
  cursor: 'pointer',
}));

const UserGreeting = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  textAlign: 'center',
  padding: '3px 0 3px 0',
  margin: '-3px 0 0 0',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: theme.palette.primary.light,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.875rem',
    fontWeight: 600,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.00rem',
  },
}));

const LogoutButton = styled('IconButton')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '0 20px',
  margin: 5,
  cursor: 'pointer',
}));

const SignupButton = styled('KitButton')(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.white,
  fontSize: '0.875rem',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: '2px 20px 3px 20px',
  margin: 5,
  cursor: 'pointer',
}));

const NavMenuBar = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    maxHeight: '135px',
    margin: 0,
    padding: 0,
    zIndex: 100,
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const NavHeaderMenu = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: '#005581',
  width: '100%',
  maxHeight: '180px',
  margin: 0,
  padding: '5px 20px 0 0',
  height: 60,
}));

const NavSearchLink = styled('KitButton')(({ theme }) => ({
  margin: 5,
  color: theme.palette.common.white,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

const NavList = styled('KitButton')(({ theme }) => ({
  list: {
    [theme.breakpoints.up('lg')]: {
      WebkitBoxAlign: 'center',
      MsFlexAlign: 'center',
      alignItems: 'center',
      WebkitBoxOrient: 'horizontal',
      WebkitBoxDirection: 'normal',
      MsFlexDirection: 'row',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
    marginTop: '0px',
    display: 'flex',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none',
    padding: '0',
  },
}));

const NavListItem = styled(ListItem)(({ theme }) => ({
  float: 'left',
  color: 'inherit',
  position: 'relative',
  display: 'block',
  width: 'auto',
  margin: '0',
  padding: '0',
  '&:hover,&:focus': {
    color: theme.palette.primary.light,
  },
  // [theme.breakpoints.down('lg')]: {
  //   '& ul': {
  //     maxHeight: '300px',
  //     overflow: 'scroll',
  //   },
  //   width: '100%',
  //   '&:not(:last-child)': {
  //     '&:after': {
  //       width: 'calc(100% - 30px)',
  //       content: '""',
  //       display: 'block',
  //       height: '1px',
  //       marginLeft: '15px',
  //       backgroundColor: '#DCDCDC',
  //     },
  //   },
  // },
}));

const LinkTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  padding: '0.45rem 1.30rem',
  fontWeight: '600',
  fontSize: '0.875rem',
  textTransform: 'uppercase',
  lineHeight: '20px',
  textDecoration: 'none',
  margin: '0px',
  display: 'inline-flex',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: theme.palette.primary.light,
  },
}));

const MobileLinkTitle = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 10,
  fontWeight: '400',
  fontSize: '1.00rem',
  textTransform: 'uppercase',
  lineHeight: '20px',
  textDecoration: 'none',
  margin: '0px',
  display: 'flex',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: '#DCDCDC',
  },
}));

const StyledNavMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginTop: '-10px',
  },
  li: {
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.common.white,
      borderRadius: '3px',
    },
  },
}));

const ToggleButton = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('lg')]: {},
}));
