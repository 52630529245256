import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Link from 'next/link';
import { renderHTML } from '@agility/nextjs';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { KitGridContainer, KitGridItem, KitButton } from '@boystownorg/bi-cms-component-lib';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';
import { getColorByAgilityName } from 'services/agilityTranslations';

export default function PlanOptionLearnMore(props) {
  const { headerImage, title, description, introduction, benefits, disclaimer, showCalculator } = props;
  const theme = useTheme();

  const [numberOfStaff, setNumberOfStaff] = useState(0);
  const [lengthOfSubscription, setLengthOfSubscription] = useState(0);
  const [costOfPlan, setCostOfPlan] = useState(0);
  const [showTotal, setShowTotal] = useState(false);

  // console.log(JSON.stringify(props, null, 2));

  const handleNumberOfStaff = (val) => {
    setShowTotal(false);
    setNumberOfStaff(val);
  };

  const handleLengthOfSubscription = (val) => {
    setShowTotal(false);
    setLengthOfSubscription(val);
  };

  const calculateCost = () => {
    let discount = 1;
    switch (lengthOfSubscription) {
      case '2':
        discount = 0.8;
        break;
      case '3':
        discount = 0.8;
        break;
      default:
        discount = 1;
        break;
    }
    setCostOfPlan(Math.round(((numberOfStaff * lengthOfSubscription * discount) / 10) * 10).toLocaleString('en-US'));
    setShowTotal(true);
  };

  const Frame = styled.div`
    margin: -25px;
  `;

  const Header = styled.div`
    width: 100%;
    background-color: ${getColorByAgilityName('light')};
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: space-around;
    margin: 0;
  `;

  const ImageContainer = styled.div`
    @media screen and (min-width: 992px) {
      width: 25%;
      background-image: url('${headerImage}');
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const TitleContainer = styled.div`
    display: flex;
    @media screen and (min-width: 992px) {
      justify-content: center;
      align-items: center;
      width: 75%;
    }
    @media screen and (max-width: 991px) {
      justify-content: center;
      align-items: center;
      width: 90%;
    }
  `;

  const Title = styled.div`
    text-transform: uppercase;
    color: ${theme.palette.common.white};
    font-weight: 700;
    text-align: center;
    @media screen and (min-width: 992px) {
      width: 90%;
      font-size: 2.625rem;
      line-height: 3.675rem;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      font-size: 1.813rem;
      line-height: 2.828rem;
    }
  `;

  const DescriptionContainer = styled.div`
    width: 100%;
  `;

  const Description = styled.div`
    color: ${theme.palette.common.black};
    font-size: 1.125rem;
    line-height: 1.75rem;
    @media screen and (min-width: 992px) {
      max-width: 100%;
      margin: 40px 30px 15px 30px;
    }
    @media screen and (max-width: 991px) {
      width: 90%;
      margin: 30px 5px 10px 10px;
    }
  `;

  const HighlightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start @media screen and (min-width: 992px) {
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  `;
  const ListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      width: 100%;
    }
  `;

  const ContentContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    vertical-align: top;
    @media screen and (min-width: 992px) {
      ${showCalculator ? 'width: 60%' : 'width: 100%;'}
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  `;

  const CalculatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    @media screen and (min-width: 992px) {
      ${showCalculator ? 'width: 40%;' : 'display: none;'};
    }
    @media screen and (max-width: 891px) {
      ${showCalculator ? 'width: 100%;' : 'display: none;'};
    }
  `;

  const Introduction = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.375rem',
      lineHeight: '3rem',
      padding: '10px 0',
      width: '100%',
      margin: '0 30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.813rem',
      lineJeight: '2.278rem',
      padding: '10px 0',
      width: '100%',
      margin: '0 15px',
    },
  }));

  const OptionDescription = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      maxWidth: '100%',
      margin: '0 30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      maxWidth: '100%',
      margin: '0 15px',
    },
  }));

  const BenefitList = styled('div')(({ theme }) => ({
    margin: '20px 30px',
  }));

  const Calculator = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.65rem',
    fontWeight: 600,
    alignSelf: 'center',
    border: `1px solid ${theme.palette.common.black}`,
    [theme.breakpoints.up('md')]: {
      width: '380px',
      margin: '10px 20px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '10px 0',
    },
  }));

  const CalculatorIntro = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.50rem',
      lineHeight: '1.875rem',
      margin: '30px 20px 35px 20px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px',
      fontSize: '1.35rem',
      lineHeight: '1.475rem',
    },
  }));

  const CalculatedCost = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.35rem',
      lineHeight: '1.675rem',
      margin: '25px 20px 15px 20px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px',
      fontSize: '1.45rem',
      lineHeight: '1.675rem',
    },
  }));

  const Disclaimer = styled('div')(({ theme }) => ({
    color: theme.palette.common.black,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      maxWidth: '100%',
      margin: '0 30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      maxWidth: '100%',
      margin: '0 15px',
    },
  }));

  return (
    <Frame>
      <Header>
        <ImageContainer></ImageContainer>
        <TitleContainer>
          <Heading3 color='#FFF' underline={true} uppercase={true}>
            {title}
          </Heading3>
        </TitleContainer>
      </Header>
      <DescriptionContainer>
        <Description>{description}</Description>
      </DescriptionContainer>
      <HighlightsContainer>
        <ListContainer>
          <ContentContainer>
            <Introduction>{"What's Included"}</Introduction>
            <OptionDescription>{introduction}</OptionDescription>
            <BenefitList className='prose max-w-full' dangerouslySetInnerHTML={renderHTML(benefits)} />
            <Disclaimer>{disclaimer}</Disclaimer>
          </ContentContainer>
          {showCalculator && (
            <CalculatorContainer>
              <Calculator>
                <CalculatorIntro>Find The Right Plan For Your Team</CalculatorIntro>
                <FormControl sx={{ width: '80%', margin: '10px' }}>
                  <Select
                    id='number-of-staff-value'
                    value={numberOfStaff}
                    placeholder='Number of Staff'
                    hiddenLabel
                    onChange={(e) => {
                      handleNumberOfStaff(e.target.value);
                    }}
                  >
                    <MenuItem value='0' disabled>
                      Select Number of Staff
                    </MenuItem>
                    <MenuItem value='8250'>1-30</MenuItem>
                    <MenuItem value='18600'>31-75</MenuItem>
                    <MenuItem value='33000'>76-150</MenuItem>
                    <MenuItem value='X'>150+</MenuItem>
                  </Select>
                </FormControl>
                {numberOfStaff === 'X' ? (
                  <CalculatedCost>Contact us for pricing</CalculatedCost>
                ) : (
                  <>
                    <FormControl fullWidth sx={{ width: '80%', margin: '10px' }}>
                      <Select
                        id='length-of-subscription-value'
                        value={lengthOfSubscription}
                        placeholder='Length of Subscription'
                        hiddenLabel
                        onChange={(e) => {
                          handleLengthOfSubscription(e.target.value);
                        }}
                      >
                        <MenuItem value='0' disabled>
                          Select a Subscription Length
                        </MenuItem>
                        <MenuItem value='1'>1 year</MenuItem>
                        <MenuItem value='2'>2 years</MenuItem>
                        <MenuItem value='3'>3 years</MenuItem>
                      </Select>
                    </FormControl>
                    <KitButton
                      onClick={(e) => calculateCost()}
                      round
                      style={{
                        backgroundColor: theme.palette.primary.light,
                        margin: '10px',
                      }}
                    >
                      CALCULATE
                    </KitButton>
                    <CalculatedCost>{showTotal && `The cost for this plan is $${costOfPlan}.`}</CalculatedCost>{' '}
                  </>
                )}
                {(showTotal || numberOfStaff === 'X') && (
                  <Link href='/contact-us' rel='noopener noreferrer' passHref>
                    <KitButton color='primary' simple style={{ textDecoration: 'underline', margin: '0px 30px 10px 30px', fontSize: '0.90rem' }}>
                      Contact Us
                    </KitButton>
                  </Link>
                )}
              </Calculator>
            </CalculatorContainer>
          )}
        </ListContainer>
      </HighlightsContainer>
    </Frame>
  );
}

PlanOptionLearnMore.propTypes = {
  headerImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  introduction: PropTypes.string,
  benefits: PropTypes.string,
  disclaimer: PropTypes.string,
  showCalculator: PropTypes.bool,
};
