import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useSession } from 'next-auth/react';

import PleaseWait from 'components/common/PleaseWait';
import { isBoysTownAdmin } from 'services/roleUtils';
import NotAuthorized from 'components/common/NotAuthorized';
import TabPanel from 'components/common/subcomponents/TabPanel';
import SchoolManager from './SchoolManager';
import DistrictManager from './DistrictManager';
import { states } from 'services/stringUtils';
import useLoadingOrSigningOut from "../../../../services/hooks/useLoadingOrSigningOut";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ManageSubscriptions = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { data: session, status } = useSession();

  const handleTabChange = async (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isWaiting = useLoadingOrSigningOut(status);
  if (isWaiting) {
    return <PleaseWait isLoading={true}/>;
  }

  if (!isBoysTownAdmin(session)) {
    return <NotAuthorized />;
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <div>
      <PleaseWait isLoading={isLoading} />
      <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

      <Box sx={{ width: '100%' }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="district and school tabs">
          <Tab label="Districts" id='district-tab' aria-controls='district-tab-panel' />
          <Tab label="Schools" id='school-tab' aria-controls='school-tab-panel' />
        </Tabs>
        <TabPanel value={currentTab} name='district' index={0}>

          <DistrictManager stateList={states} user={session.user} />

        </TabPanel>
        <TabPanel value={currentTab} name='school' index={1}>
          <SchoolManager stateList={states} user={session.user} />
        </TabPanel>
      </Box>

    </div>
  )
}

export default ManageSubscriptions;