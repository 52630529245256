import React from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import Typography from '@mui/material/Typography';
import { useSession } from 'next-auth/react';
import { COMMON_ROUTES } from 'services/constants';
import { useTheme } from '@mui/material/styles';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';

const TopBannerWithHeading = ({ module }) => {
  const { fields } = module;

  const theme = useTheme();

  const router = useRouter();
  const { data: session, status } = useSession();

  const Container = styled.div`
    @media screen and (max-width: 899px) {
      display: none;
    }
    @media screen and (min-width: 900px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: left 10% top 10%;
      position: relative;
      padding: 10px 20px;
    }
  `;

  const MobileContainer = styled.div`
    @media screen and (max-width: 899px) {
      height: 250px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-size: 120%;
      background-repeat: no-repeat;
      background-position: right 40% top 10%;
      position: relative;
      padding: 10px 10px;
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: inherit;
    @media screen and (max-width: 899px) {
      margin: 25px 5% 0 5%;
      max-width: 95%;
      justify-content: flex-start;
    }
    @media screen and (min-width: 900px) {
      margin: 0 15%;
      max-width: 85%;
      justify-content: flex-end;
    }
  `;

  return (
    <React.Fragment>
      <Container
        style={{
          backgroundImage: `linear-gradient(to right, rgba(7, 30, 49, 1), rgba(5, 50, 77, 0.76), rgba(0, 85, 128, 0.32)), url(${fields.backgroundImage.url})`,
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <TextContainer>
          <FreeformHeader
            level={fields?.headingLevel || 'h1'}
            text={fields.headingText}
            color={headingColor(theme, fields?.headingStyle?.fields?.color)}
            style={{ margin: '4rem 30% 1.5rem 0' }}
          />
          <Description>{fields.descriptionText}</Description>
          <ButtonContainer>
            {fields.displayCTAButton === 'yes' && (
              <KitButton
                round
                size='lg'
                style={{
                  backgroundColor: buttonColor(theme, fields?.buttonColor),
                  border: '1px solid ' + buttonColor(theme, fields?.buttonBorderColor),
                  color: buttonColor(theme, fields?.buttonTextColor),
                  marginRight: '1rem',
                }}
                onClick={() => {
                  const element = document.getElementById(fields.ctaLink);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' }); // Use 'auto' or omit for instant scroll
                  }
                }}
              >
                {fields.ctaButtonText}
              </KitButton>
            )}
          </ButtonContainer>
        </TextContainer>
      </Container>
      <MobileContainer
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.6)), url(${fields.backgroundImage.url})`,
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        <TextContainer>
          <FreeformHeader level={'h2'} text={fields?.headingText} color={headingColor(theme, fields?.headingStyle?.fields?.color)} />
          <MobileDescription>{fields.descriptionText}</MobileDescription>
          {fields.displayCTAButton === 'yes' && (
            <ButtonContainer>
              <KitButton
                round
                size='sm'
                style={{
                  backgroundColor: fields?.buttonColor === 'transparent' ? 'transparent' : buttonColor(theme, fields?.buttonColor),
                  border: '1px solid ' + buttonColor(theme, fields?.buttonBorderColor),
                  color: buttonColor(theme, fields?.buttonTextColor),
                  marginRight: '1rem',
                }}
                onClick={() => {
                  const element = document.getElementById(fields.ctaLink);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' }); // Use 'auto' or omit for instant scroll
                  }
                }}
              >
                {fields.ctaButtonText}
              </KitButton>
            </ButtonContainer>
          )}
        </TextContainer>
      </MobileContainer>
    </React.Fragment>
  );
};

export default TopBannerWithHeading;

const Description = styled('Typography')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.5rem',
  lineHeight: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  width: '642px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileDescription = styled('Typography')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.125rem',
  lineHeight: '1.438rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  width: '100%',
  padding: '5% 0',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '30px 0 80px 0',
    alignSelf: 'flex-start',
    bottom: '-180px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    // bottom: '-30px',
  },
}));
