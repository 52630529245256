import { UseQueryResult, skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { callApi } from 'services/apiWrapper';
import { VectorStoreIndexRequestType, VectorStoreSiteMapNode } from 'types/VectorStoreTypes';

export const vectorPageInfoKeys = {
  vectorPageInfo: () => ['vectorPageInfo'],
  vectorPageRequest: (path: string) => ['vectorPageRequest', { path }],
};

const addScrapeRequest = async (instance: VectorStoreIndexRequestType): Promise<void> => {
  if (instance.path) {
    await callApi('/api/db/vectorstore/submit', 'POST', instance);
  }
};

export const useAddScrapeRequest = () => {
  return useMutation({
    mutationFn: (instance: VectorStoreIndexRequestType) => addScrapeRequest(instance),

    retry: false,
  });
};

const fetchPageInfo = async () => {
  const res = await callApi('/api/db/vectorstore/pages', 'GET');
  return res;
};

export const useVectorStorePageInfo = (hasSession): UseQueryResult<VectorStoreSiteMapNode, Error> => {
  return useQuery({
    queryKey: vectorPageInfoKeys.vectorPageInfo(),
    queryFn: hasSession ? () => fetchPageInfo() : skipToken,
    staleTime: 60 * 5 * 1000,
  });
};
