import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import AssistantIcon from '@mui/icons-material/Assistant';
import { Freeform } from 'components/common/Freeform';

const ErrorMessage = ({ message, index }) => {
  const theme = useTheme();
  let blocks = null;
  try {
    blocks = message.content ? JSON.parse(message.content)?.blocks : null;
  } catch (error) {}

  return (
    <Box
      key={index}
      sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.mediumDark,
          borderRadius: '1rem',
        }}
      >
        <Box sx={{ alignSelf: 'flex-start', marginLeft: '1rem', paddingTop: '1rem' }}>
          <AssistantIcon color='primary' />
        </Box>
        <Box
          sx={{
            padding: '1rem',
            color: theme.palette.primary.dark,
            '& a': {
              textDecoration: 'underline',
            },
          }}
        >
          {blocks && <Freeform blocks={blocks}></Freeform>}
          {!blocks && <div>{message.content}</div>}
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorMessage;
