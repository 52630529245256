import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import { useSession } from 'next-auth/react';
import { KitGridContainer, KitGridItem, KitButton, KitTable } from '@boystownorg/bi-cms-component-lib';
import PleaseWait from 'components/common/PleaseWait';
import { logClientException } from 'appinsights/clientAppInsights';
import { isAdmin, ROLE_CONST } from 'services/roleUtils';
import { callApi } from 'services/apiWrapper';
import NotAuthorized from 'components/common/NotAuthorized';
import { formatDate } from 'services/stringUtils';
import CustomFilter from '../../../common/subcomponents/CustomFilter';
import SortableTableHeader from '../../../common/subcomponents/SortableTableHeader';
import CouponCodeCard from './CouponCodeCard';
import AddCouponCodeDialog from './AddCouponCodeDialog';
import { PRODUCTS } from 'services/constants';
import useLoadingOrSigningOut from "../../../../services/hooks/useLoadingOrSigningOut";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ManageCouponCodes = () => {
  const { data: session, status } = useSession();
  const [errorMessage, setErrorMessage] = useState(null);
  const [addEditState, setAddEditState] = useState({
    open: false,
    action: 'Add',
    editCouponCode: null,
  });
  const fetching = useRef(0);

  const [pageState, setPageState] = useState({
    allRecords: [],
    tableRecords: [],
    filter: '',
    fetchedRecords: false,
    showAllRecords: false,
    sortDirection: 'asc',
    sortBy: '',
  });
  const [paginationOptions, setPaginationOptions] = useState({
    searching: false,
    page: 0,
    perPage: 10,
    totalRecords: 0,
  });

  const sortOptions = useRef({ field: 'exp_date', direction: 'DESC' });

  const filterOptions = useRef({ is_active: true });

  const isLoading = useLoadingOrSigningOut(status);

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (isAdmin(session)) {
      init();
    }
  }, [session, isLoading]);

  const init = async () => {
    await fetchCouponCodes();
  };

  if (isLoading) {
    return <PleaseWait isLoading={true} />;
  }

  if (!isAdmin(session)) {
    return <NotAuthorized />;
  }

  const fetchCouponCodes = async (perPage = 10, page = 0, append = false) => {
    try {
      fetching.current += 1;
      setPaginationOptions({ ...paginationOptions, searching: true });
      let path = `/api/db/coupon-code-list?page_size=${perPage}&page_number=${page}`;
      let qs = [];

      if (filterOptions.current) {
        for (const key in filterOptions.current) {
          if (filterOptions.current.hasOwnProperty(key)) {
            qs.push(`filter.${key}=${filterOptions.current[key]}`);
          }
        }
      }
      qs.push(`order_by=${sortOptions.current.field}:${sortOptions.current.direction}`);

      if (qs.length > 0) {
        path += `&${qs.join('&')}`;
      }
      let res = await callApi(path);

      const tableRecords = res.results.map((r) => [
        r.code,
        PRODUCTS.find((p) => p.id === r.product)?.name ?? '',
        formatDate(r.exp_date),
        r.type,
        r.value,
        <Checkbox key={r.code} checked={r.max_usage > 0} disabled title={r.max_usage} />,
        <Checkbox key={r.code} checked={r.is_active} disabled />,
        <KitButton round key={r.code} color='info' justIcon size='sm' onClick={() => showEdit(r)} title='Edit code'>
          <EditIcon />
        </KitButton>,
      ]);
      let allRecords = [...pageState.allRecords];
      const formatRecords = (recs) => {
        return recs.map((r) => {
          return { ...r, exp_date: formatDate(r.exp_date) };
        });
      };
      if (append) {
        formatRecords(res.results).forEach((r) => {
          allRecords.push(r);
        });
      } else {
        allRecords = formatRecords(res.results);
      }
      fetching.current -= 1;
      setPageState({ ...pageState, allRecords: allRecords, tableRecords: tableRecords });
      setPaginationOptions({ ...paginationOptions, totalRecords: res.totalRecords, perPage, page, searching: false });
    } catch (error) {
      console.log(error);
      logClientException(error);
      fetching.current -= 1;
      setErrorMessage(defaultErrorMessage);
    }
  };

  const showEdit = (code) => {
    setAddEditState({ open: true, action: 'Update', editCouponCode: code });
  };

  const showAdd = () => {
    setAddEditState({ open: true, action: 'Add', editCouponCode: null });
  };

  const handleAddEditClose = async (doSave) => {
    setAddEditState({ ...addEditState, open: false });
    if (doSave) {
      await fetchCouponCodes();
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const filterOnChange = async (data) => {
    filterOptions.current = data;
    await fetchCouponCodes(paginationOptions.perPage, paginationOptions.page);
  };

  const onSortChange = async (field, direction) => {
    if (sortOptions.current.field === field) {
      sortOptions.current.direction = sortOptions.current.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      sortOptions.current.field = field;
      sortOptions.current.direction = direction ? direction : 'ASC';
    }
    await fetchCouponCodes(paginationOptions.perPage, paginationOptions.page);
  };

  const getSortDirection = (field) => {
    return sortOptions.current.field === field ? sortOptions.current.direction : null;
  };

  const headerCols = [
    <SortableTableHeader key='code' field='code' text='Coupon Code' sortDirection={getSortDirection('code')} onSortChange={onSortChange} />,
    <SortableTableHeader key='product' field='product' text='Product' sortDirection={getSortDirection('product')} onSortChange={onSortChange} />,
    <SortableTableHeader key='exp_date' field='exp_date' text='Exp Date' sortDirection={getSortDirection('exp_date')} onSortChange={onSortChange} />,
    <SortableTableHeader key='type' field='type' text='Type' sortDirection={getSortDirection('type')} onSortChange={onSortChange} />,
    <SortableTableHeader key='value' field='value' text='Value' sortDirection={getSortDirection('value')} onSortChange={onSortChange} />,
    <SortableTableHeader
      key='is_max_usage'
      field='max_usage'
      text='Is Max Usage'
      sortDirection={getSortDirection('max_usage')}
      onSortChange={onSortChange}
    />,
    <SortableTableHeader key='is_active' field='is_active' text='Active' sortDirection={getSortDirection('is_active')} onSortChange={onSortChange} />,
    '',
  ];

  const customFilterFields = () => {
    let fields = [];
    const user = session.user;
    if (user?.role === ROLE_CONST.BoysTownAdmin) {
      fields.push({ name: 'code', label: 'Coupon Code' });
    }
    fields.push({ name: 'type', label: 'Type' });
    fields.push({ name: 'is_active', label: 'Active Only', value: true, type: 'checkbox' });
    return fields;
  };

  return (
    <React.Fragment>
      <PleaseWait isLoading={fetching.current > 0} />
      <Snackbar open={errorMessage} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} data={filterOptions.current} />
        {pageState.allRecords.map((r, i) => (
          <CouponCodeCard key={r.id} couponCode={r} editFunc={() => showEdit(r)} />
        ))}
        {paginationOptions.totalRecords > (paginationOptions.page + 1) * paginationOptions.perPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <KitButton
              size='sm'
              round
              color='secondary'
              onClick={() => fetchCouponCodes(paginationOptions.perPage, paginationOptions.page + 1, true)}
            >
              Load More
            </KitButton>
          </Box>
        )}
      </Box>

      <Box sx={{ display: { sm: 'block', xs: 'none' }, marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
        <CustomFilter fields={customFilterFields()} onChange={filterOnChange} data={filterOptions.current} />
        <KitGridContainer direction='row' align='right'>
          <KitGridItem xs={12}>
            <KitButton size='sm' round color='primary' onClick={showAdd}>
              <AddCircleIcon /> Add Coupon Code
            </KitButton>
          </KitGridItem>
        </KitGridContainer>
        <KitGridContainer direction='row' align='center'>
          <KitGridItem xs={12}>
            <TableContainer>
              <KitTable striped hover tableData={pageState.tableRecords} tableHead={headerCols}></KitTable>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={paginationOptions.totalRecords}
                rowsPerPage={paginationOptions.perPage}
                page={paginationOptions.page}
                onPageChange={(ev, val) => {
                  fetchCouponCodes(paginationOptions.perPage, val);
                }}
                onRowsPerPageChange={(ev) => {
                  const perPage = parseInt(ev.target.value, 10);
                  fetchCouponCodes(perPage, 0);
                }}
                showFirstButton
                showLastButton
              />
            </TableContainer>
          </KitGridItem>
        </KitGridContainer>
      </Box>

      <AddCouponCodeDialog
        open={addEditState.open}
        onClose={handleAddEditClose}
        action={addEditState.action}
        user={session.user}
        editCode={addEditState.editCouponCode}
      />
    </React.Fragment>
  );
};

export default ManageCouponCodes;
