import React, { useState } from 'react';
import styled from '@emotion/styled';
import { renderHTML } from '@agility/nextjs';
import { useTheme } from '@emotion/react';
import { parseBlocks } from 'services/parseUtils';
import { Freeform } from 'components/common/Freeform';
import { FreeformHeader } from 'components/common/Freeform/FreeformHeader';
import { FreeformImage } from 'components/common/Freeform/FreeformImage';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { Button } from '@mui/material';
import Section from 'components/common/subcomponents/Section';
import { headingColor, headingStyle } from 'services/styleUtils/headingStyleUtils';
import { getContentStyle } from 'services/styleUtils/contentStyleUtils';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';

const HeadingTextAndMedia = (props) => {
  const {
    module: { fields },
  } = props;

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 1000px) {
      width: 100%;
      flex-direction: column;
    }
    @media screen and (min-width: 1001px) {
      width: 100%;
      ${fields.textPosition === 'left' ? 'flex-direction: row-reverse;' : 'flex-direction: row;'}
    }
  `;

  const ImageContainer = styled.div`
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    @media screen and (min-width: 1001px) {
      width: 50%;
    }
  `;

  const TextContainer = styled.div`
    @media screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;
      margin-top: 20px;
    }
    @media screen and (min-width: 1001px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      ${fields.textPosition === 'left' ? 'margin: 0 60px 0 40px;' : 'margin: 0 50px 0 70px;'}
      width: 45%;
    }
  `;

  const ButtonContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 5px;
    width: 100%;
  `;

  const callAction = async (url, target) => {
    const win = window.open(url, target || '_self');
    win.focus();
  };

  const theme = useTheme();

  const blocks = parseBlocks(fields.contentText);
  const contentStyle = getContentStyle(theme, fields.contentStyle?.fields);

  return (
    <Section styleProps={fields.sectionStyle} sectionId={fields.sectionId}>
      <Container>
        <ImageContainer>
          {fields?.image && (
            <FreeformImage
              file={{ url: fields?.image.url, size: { height: fields?.image.height, width: fields?.image.width } }}
              caption={fields?.image.label}
            />
          )}
        </ImageContainer>
        <TextContainer>
          <FreeformHeader
            level={fields?.headingLevel || 'h2'}
            text={fields?.headingText}
            color={headingColor(theme, fields.headingStyle?.fields?.color)}
            style={{ marginBottom: '1rem', ...headingStyle(fields.headingStyle?.fields?.alignment) }}
          />

          <div
            style={{
              color: contentStyle.textColor,
              textAlign: contentStyle.alignment,
              fontSize: contentStyle.fontSize,
              lineHeight: contentStyle.lineHeight,
            }}
          >
            <Freeform blocks={blocks}></Freeform>
          </div>

          <ButtonContainer>
            {fields?.callsToAction?.sort().map(({ contentID, fields: { label, style, link } }) => (
              <KitButton
                key={contentID}
                round
                size='sm'
                style={{
                  backgroundColor: buttonColor(theme, style?.fields?.color),
                  marginRight: '1rem',
                }}
                onClick={(e) => callAction(link.href, link.target)}
              >
                {label}
              </KitButton>
            ))}
          </ButtonContainer>
        </TextContainer>
      </Container>
    </Section>
  );
};

export default HeadingTextAndMedia;
